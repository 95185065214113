<template>
  <ValidationObserver v-slot="{ passes }">
    <v-form @submit.prevent="passes(userUpdate)">
      <v-card>
        <v-card-title>
          User update
        </v-card-title>
        <v-card-text>
          <ValidationProvider
            v-slot="{ errors }"
            name="name"
            rules="required"
          >
            <v-text-field
              v-model="user.name"
              :error-messages="errors"
              label="Name"
              type="text"
              required
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="email"
            rules="required|email"
          >
            <v-text-field
              v-model="user.email"
              :error-messages="errors"
              label="Email"
              type="email"
              required
            />
          </ValidationProvider>
          <v-autocomplete
            v-model="user.role_id"
            :items="roles"
            item-value="id"
            item-text="display_name"
            label="Roles"
          />
        </v-card-text>
        <v-card-actions class="pb-3">
          <v-spacer />
          <v-btn
            type="submit"
            color="primary"
            :loading="loading"
          >
            {{ $t('action.update') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </ValidationObserver>
</template>

<script>
import authHeader from '@/utils'

export default {
  name: 'Update',
  breadcrumb () {
    return {
      label: this.$t('action.edit') + ' ' + this.user.name
    }
  },
  data () {
    return {
      loading: false,
      user: {},
      roles: []
    }
  },
  beforeMount () {
    this.getRoles()
    this.getUserByID()
  },
  methods: {
    getRoles () {
      this.$http
        .get('/roles', { headers: authHeader() })
        .catch(error => this.displayError(error))
        .then(({ data: { data } }) => {
          this.roles = data
        })
    },
    getUserByID () {
      this.$http
        .get('/users/?id[]=' + this.$route.params.id, { headers: authHeader() })
        .catch(error => this.displayError(error))
        .then(({ data: { data } }) => {
          if (!data[0]) {
            this.$router.push('/404')
          } else {
            this.user = data[0]
            if (data[0].roles[0]) {
              this.user.role_id = data[0].roles[0].id
            }
          }
        })
    },
    userUpdate () {
      this.loading = true
      this.$http
        .put('/users/' + this.$route.params.id, this.user, { headers: authHeader() })
        .then(() => {
          this.$store.commit('setNotification', { color: 'success', message: this.$t('action.update_success') })
          this.$router.push({ name: 'dashboard.user' })
        })
        .catch(error => {
          this.loading = false
          this.displayError(error)
        })
    }
  }
}
</script>
